import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import { AuctionDlgProvider } from '../contexts/AuctionDlgContext';
import { MintDlgProvider } from '../contexts/MintDlgContext';
import LandingLayout from '../layouts/landing';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    { path: '/coming-soon', element: <ComingSoonPage /> },
    {
      path: '/home',
      element: <LandingLayout />,
      children: [

        { path: '/home', element: <LandingPage /> },
        { path: '/home/get-started', element: <LandingGetStartedPage /> },
        { path: '/home/company', element: <LandingCompanyPage /> },
        { path: '/home/login', element: <LandingLoginPage /> },
        { path: '/home/register', element: <LandingRegisterPage /> },
        { path: '/home/learn-more', element: <LandingLearnMorePage /> },
      ]
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <MarketHome /> },
        { path: 'activate-user', element: <ActivateUser /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'explorer', element: <Explorer /> },
        { path: 'inventory', element: <Inventory /> },
        {
          path: 'explorer',
          children: [
            { path: 'collectible', element: <Collectible /> },
            { path: 'collectible/:collection', element: <Collectible /> },
            { path: 'collectible/detail/:args', element: <CollectibleDetail /> },
            { path: 'transaction', element: <Transaction /> },
            { path: 'transaction/:transaction', element: <Transaction /> },
            { path: 'transaction/detail/:address', element: <AddressDetail /> },
            { path: 'search', element: <Navigate to="/explorer/collectible" replace /> },
            { path: 'search/:key', element: <SearchResult /> },
          ]
        },
        { path: 'apollo-b', element: <ApolloB /> },
        { path: 'marketplace/:storePath?', element: <MarketExplorer /> },
        {
          path: 'marketplace',
          children: [
            { path: 'detail/:args', element: <AuctionDlgProvider><MarketCollectibleDetail /></AuctionDlgProvider> },
            { path: 'search', element: <Navigate to="/marketplace" replace /> },
            { path: 'search/:key', element: <MarketExplorer /> },
          ]
        },
        { path: 'listauction', element: <MintDlgProvider><CreateItem /></MintDlgProvider> },
        { path: 'create', element: <MintDlgProvider><CreateItem /></MintDlgProvider> },
        { path: 'collections', element: <CollectionExplorer /> },
        {
          path: 'collections',
          children: [
            { path: 'detail/:collection', element: <CollectionDetail /> },
          ]
        },
        { path: 'profile/:args?', element: <MyProfile /> },
        {
          path: 'profile',
          children: [
            { path: 'myitem/:type', element: <MyProfile /> },
          ]
        },
        { path: 'admin-panel', element: <AdminPanel /> },
        { path: 'activity', element: <Activity /> },
        { path: 'features', element: <Features /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}


// User
const ActivateUser = Loadable(lazy(() => import('../pages/ActivateUser')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));

// Explorer
const Explorer = Loadable(lazy(() => import('../pages/Explorer')));
const Inventory = Loadable(lazy(() => import('../pages/Inventory')));
const Collectible = Loadable(lazy(() => import('../pages/explorer/Collectible')));
const SearchResult = Loadable(lazy(() => import('../pages/explorer/SearchResult')));
const CollectibleDetail = Loadable(lazy(() => import('../pages/explorer/CollectibleDetail')));
const MarketCollectibleDetail = Loadable(lazy(() => import('../pages/marketplace/CollectibleDetail')));
const Transaction = Loadable(lazy(() => import('../pages/explorer/Transaction')));
const AddressDetail = Loadable(lazy(() => import('../pages/explorer/AddressDetail')));
// Marketplace
const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
const MarketHome = Loadable(lazy(() => import('../pages/MarketHome')));
const MarketExplorer = Loadable(lazy(() => import('../pages/marketplace/Explorer')));
const CreateItem = Loadable(lazy(() => import('../pages/marketplace/CreateItem')));
const MyProfile = Loadable(lazy(() => import('../pages/marketplace/MyProfile')));
const AdminPanel = Loadable(lazy(() => import('../pages/admin/AdminPanel')));
const ApolloB = Loadable(lazy(() => import('../pages/marketplace/ApolloB')));
// Collection
const CollectionExplorer = Loadable(lazy(() => import('../pages/collection/Collection')));
const CollectionDetail = Loadable(lazy(() => import('../pages/collection/CollectionDetail')));
// Activity
const Activity = Loadable(lazy(() => import('../pages/activity/Explorer')));
// Features
const Features = Loadable(lazy(() => import('../pages/features/Features')));
// Rewards

// Landing
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const LandingGetStartedPage = Loadable(lazy(() => import('../pages/landing/LandingGetStartedPage')));
const LandingLoginPage = Loadable(lazy(() => import('../components/landing/LandingLoginPage')));
const LandingRegisterPage = Loadable(lazy(() => import('../components/landing/LandingRegisterPage')));
const LandingLearnMorePage = Loadable(lazy(() => import('../pages/landing/LandingLearnMorePage')));
const LandingCompanyPage = Loadable(lazy(() => import('../pages/landing/LandingCompanyPage')));


const NotFound = Loadable(lazy(() => import('../pages/Page404')));