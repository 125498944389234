import { Icon } from '@iconify/react';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'apolloB',
    icon: <DiamondOutlinedIcon fontSize="medium" />,
    path: PATH_PAGE.apolloB,
    disable: false
  },
  {
    title: 'collections',
    icon: <LocalGroceryStoreOutlinedIcon fontSize="medium" />,
    path: PATH_PAGE.marketplace,
    disable: false
  },
  {
    title: 'marketplace',
    icon: <CollectionsBookmarkOutlinedIcon fontSize="medium" />,
    path: PATH_PAGE.collection,
    disable: false
  },
  {
    title: 'savedDesign',
    icon: <FavoriteBorderOutlinedIcon fontSize="medium" />,
    path: PATH_PAGE.saved,
    disable: false
  },
  // {
  //   title: 'Explorer',
  //   icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
  //   path: PATH_PAGE.explorer,
  //   disable: false
  // },
  // {
  //   title: 'list',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.create,
  //   disable: false
  // },
  // {
  //   title: 'auction',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.auction,
  //   disable: false
  // },
  // {
  //   title: 'Activity',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.activity,
  //   disable: false
  // },
  // {
  //   title: 'Features',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.features,
  //   disable: false
  // },
  // {
  //   title: 'Rewards',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_PAGE.rewards,
  //   disable: false
  // },
  // {
  //   title: 'Docs',
  //   icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
  //   path: PATH_DOCS,
  //   disable: true
  // }
];

export default menuConfig;
