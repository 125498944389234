import { Box, Button } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HexagonButton } from './HexagonButton';
import HexagonButtonWithBorder from './HexagonButtonWithBorder';

const LandingNavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%', padding: { md: '10px 60px', lg: '10px 150px' }, display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <RouterLink to="/home">
        <Box
          component="img"
          alt=""
          src="/static/landingPage/logos/colorfulLogo.svg"
          sx={{ width: 175, height: 70, cursor: 'pointer' }}
        />
      </RouterLink>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={() => navigate('/home/learn-more')} sx={{ color: 'white' }}>{t("learnMore")}</Button>
        <Button onClick={() => navigate('/home/company')} sx={{ ml: 3, color: 'white' }}>{t("company")}</Button>
        <HexagonButton onClick={() => navigate('/home/get-started')} sx={{ ml: 3, color: 'black' }}>{t("getStartedUppercase")}</HexagonButton>
        <HexagonButtonWithBorder onClick={() => navigate('/home/register')} sx={{ ml: 3 }} title={t("registerNowUpperCase")} />
      </Box>
    </Box >
  );
};

export default LandingNavBar;
