import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// import telegramIcon from '@iconify/icons-ic/outline-telegram';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, ListItemButton, Button } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';
// components
import { HexagonButton } from '../../components/landing/HexagonButton';
import HexagonButtonWithBorder from '../../components/landing/HexagonButtonWithBorder';

// ----------------------------------------------------------------------

const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary
}));

// ----------------------------------------------------------------------


export default function MobileNavbar() {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Box sx={{ width: '100%', p: '0 20px', display: 'flex', justifyContent: 'space-between' }}>
        <RouterLink to="/home">
          <Box
            component="img"
            alt=""
            src="/static/landingPage/logos/colorfulLogo.svg"
            sx={{ width: 125, height: 50, cursor: 'pointer' }}
          />
        </RouterLink>
        <MIconButton
          onClick={handleDrawerOpen}
          sx={{
            ml: 1,
          }}
        >
          <Icon icon={menu2Fill} />
        </MIconButton>
      </Box>
      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260, background: '#09143A' } }}
      >
        <Scrollbar sx={{ minHeight: 250 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
            <RouterLink to="/home">
              <Box
                component="img"
                alt=""
                src="/static/landingPage/logos/colorfulLogo.svg"
                sx={{ width: 175, height: 70, cursor: 'pointer', mt: 3 }}
              />
            </RouterLink>
            <Button onClick={() => navigate('/home/learn-more')} sx={{ color: 'white', mt: 3 }}>{t("learnMore")}</Button>
            <Button onClick={() => navigate('/home/company')} sx={{ mt: 3, color: 'white' }}>{t("company")}</Button>
            <HexagonButton onClick={() => navigate('/home/get-started')} sx={{ mt: '10px', color: 'black' }}>{t("getStartedUppercase")}</HexagonButton>
            <HexagonButtonWithBorder onClick={() => navigate('/home/register')} sx={{ mt: 3 }} title={t("registerNowUpperCase")} />
          </Box>
        </Scrollbar>
      </Drawer >
    </>
  );
}