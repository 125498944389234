import { styled, Button } from '@mui/material';

const HexagonButtonWithBorder = ({ onClick, title, sx, textSx }) => (
  <Button
    onClick={onClick}
    sx={{
      ...sx,
      color: 'white',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      height: 'fit-content',
      '&:hover': {
        backgroundColor: 'inherit'
      },
      '& .button-content': {
        ...textSx,
        position: 'absolute',
        backgroundImage: 'linear-gradient(to right, #7C5BF9, #C669F5 21%, #94EEF6 97%)',
        backgroundClip: 'text',
        color: 'transparent'
      }
    }}
  >
    <img
      alt="learnMoreButton"
      src="/static/LandingPage/hexagonButtonBorder.svg"
      style={{
        objectFit: 'contain',
        maxHeight: '150%',
        maxWidth: 'fit-content'
      }}
    />
    <div className="button-content">
      {title}
    </div>
  </Button>
);



export default HexagonButtonWithBorder;
